

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { RejectResponse } from 'common-modules/src/store/moduleStore';
import CommonFormElement from 'common-modules/src/components/CommonFormElement.vue';
import { ExtendedRunTask } from '@/store/interface/Task';
import JwlSubmitRow from '@/components/JwlSubmitRow.vue';

@Component({
  components: {
    JwlSubmitRow,
    CommonFormElement,
  },
})
export default class TaskPublish extends Vue {
  $refs!: {
    eulaReference: CommonFormElement;
  }

  @Prop(Object)
  lmsTask!: ExtendedRunTask;

  @Prop(Number)
  syncStatus!: number;

  @Prop(Object)
  syncError!: RejectResponse;

  handleEmit (): void {
    this.$emit('on-click');
  }

  get publishButtonTranslation (): string {
    return 'task.publishNow';
  }
}
